import { template as template_c9bb3fe5912947bba1f27e3884e7c47d } from "@ember/template-compiler";
const FKControlMenuContainer = template_c9bb3fe5912947bba1f27e3884e7c47d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
