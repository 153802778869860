import { template as template_498a7e119b9d4e8797369e66f8216beb } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKCol from "discourse/form-kit/components/fk/col";
const FKRow = template_498a7e119b9d4e8797369e66f8216beb(`
  <div class="form-kit__row" ...attributes>
    {{yield (hash Col=FKCol)}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKRow;
