import { template as template_fa0e72fe58fb47728a10c6f22185f6f4 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_fa0e72fe58fb47728a10c6f22185f6f4(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
