import { template as template_0928768991ab432c8cbc01ccd920e4dd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_0928768991ab432c8cbc01ccd920e4dd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
